<template>
  <section id="home">
    <b-row>
      <b-col>
        Home
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeHome from './storeHome'
import constHome from './constHome'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      homeData: {},
    }
  },
  mounted() {
    this.getDefaultHome()
  },
  methods: {
    getDefaultHome() {
      this.dateLoading = true
      store
        .dispatch('store-home/getDefaultHome', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.homeData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.dateLoading = false
        })
    },
  },
  setup() {
    if (!store.hasModule('store-home')) store.registerModule('store-home', storeHome)
    onUnmounted(() => {
      if (store.hasModule('store-home')) store.unregisterModule('store-home')
    })

    const {
      refetchData,

      locale,
    } = constHome()

    return {
      refetchData,

      locale,
    }
  },
}
</script>
