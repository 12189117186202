import { ref, watch } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'

export default function constHome() {
  const locale = ref('th')
  const list = ref(0)

  const refetchData = () => {
  }

  watch([locale], () => {
    localStorage.setItem(`${$themeConfig.app.session}-filter-home`, JSON.stringify({
      language: locale.value,
    }))
    refetchData()
  })

  return {
    refetchData,

    locale,
    list,
  }
}
